
.construction-img {
  max-width: 500px;
  width: 70%;
  height: auto;
  display: inline-block;
  margin-top: 10px;
  object-fit: cover;
  margin-left: 0px;
}

/* Mobile responsive styling using a media query */
@media (max-width: 768px) {
  .construction-img {
    width: 60%;           /* Make image width 100% on mobile */
    height: auto;          /* Adjust height to maintain aspect ratio */
    max-width: 200px;      /* Limit max-width for mobile */
  }
}

  