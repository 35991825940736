
.hero-section-construction {
  background: url("../images/Market_Commercial.jpg") no-repeat center center;
  background-size: cover;
  background-position: center center;
  background-attachment: scroll; 
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  width: 100%; 
  min-height: 100vh; 
}

/* @keyframes zoomIn {
  0% {
    transform: scale();
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
} */

.overlay-construction {
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0.3),
    rgba(34, 34, 34, 0.9)
  );
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.subtitle-construction {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #fe5d14 !important;
  letter-spacing: 1.5px;
}

.title-construction {
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: bold;
  text-transform: uppercase;
  margin: 1rem 0;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}

.description-construction {
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 500;
  margin-bottom: 2rem;
  color: #ffffff !important;
}

.search-bar-construction {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px; 
  margin-left: auto; 
  margin-right: auto; 
}

.search-bar-construction .form-control-construction {
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  height: 50px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  border: 2px solid #ccc;
  width: 70%; 
}

.search-bar-construction .form-control-construction:focus {
  /* border-color: #fe5d14; */
  box-shadow: 0 0 8px rgba(254, 93, 20, 0.7);
  outline: none;
}

/* Button Style */
.search-button-construction {
  
  color: white;
  border-radius: 8px;
  font-weight: bold;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  transition: all 0.3s ease-in-out;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .search-bar-construction {
    flex-direction: column;
    align-items: center;
  }

  .search-bar-construction .form-control-construction {
    margin-bottom: 10px;
    width: 100%;
  }

  .search-bar-construction .search-button-construction {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .search-bar-construction .form-control-construction {
    font-size: 1rem;
    padding: 1rem;
  }

  .search-bar-construction .search-button-construction {
    font-size: 1rem;
    padding: 1rem;
  }
}

input::placeholder {
  font-size: 1rem;
  color: #aaa;
  animation: placeholderBlink 3s infinite;
}

@keyframes placeholderBlink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}











.construction-services-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.construction-services-section h2 {
  font-size: 2.5rem;
  font-weight: 600;
}

.construction-service-card {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; 
  width: 100%;
  max-width: 100%; 
}

.construction-service-card h4 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.construction-service-card p {
  font-size: 1rem;
  color: #6c757d;
  flex-grow: 1; }
@media (max-width: 1199px) {
  .construction-services-section h2 {
    font-size: 2rem;
  }

  .construction-service-card h4 {
    font-size: 1.3rem;
  }
}


@media (max-width: 1024px) {
  .construction-services-section h2 {
    font-size: 1.8rem;
  }

  .construction-service-card {
    padding: 1.5rem;
  }

  .construction-service-card h4 {
    font-size: 1.2rem;
  }

  .construction-service-card p {
    font-size: 0.95rem;
  }
}


@media (max-width: 767px) {
  .construction-services-section h2 {
    font-size: 1.5rem;
  }

  .construction-service-card {
    padding: 1rem;
  }

  .construction-service-card h4 {
    font-size: 1.1rem;
  }

  .construction-service-card p {
    font-size: 0.9rem;
  }
}


@media (max-width: 425px) {
  .construction-services-section h2 {
    font-size: 1.3rem;
  }

  .construction-service-card {
    padding: 0.8rem;
  }

  .construction-service-card h4 {
    font-size: 1rem;
  }

  .construction-service-card p {
    font-size: 0.85rem;
  }
}


.general-contracting {
  background-color: rgba(254, 93, 20, 0.3);
}

.design-architecture {
  background-color: rgba(0, 123, 181, 0.4);
}

.construction-engineering {
  background-color: rgba(254, 93, 20, 0.3);
}

.project-management {
  background-color: rgba(0, 123, 181, 0.4);
}



.text-styles {
  color: #FE5D14; 
} 


.image-text-section-construction   {
  margin-top: 60px !important;
  margin-bottom: 80px !important
}

.large-image {
  width: 100%; 
  max-width: 600px !important; 
  height: 450px !important; 
  object-fit: cover; 
  border-radius: 8px;
}

.circular-image {
  width: 150px; 
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: -20px;
  right: -20px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 3px solid white; 
}

.text-section-content-construction.h2 {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 20px;
  margin-top: 20px;
}

.text-section-content-construction.p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.text-section-content-construction {
  padding-left: 3rem !important;
}

.ps-lg-5 {
  padding-left: 3rem !important;
}


@media (max-width: 1200px) {
  .large-image {
    max-width: 90%; 
    height: 450px !important; 
    margin-top: -10%;
  }

  .circular-image {
    width: 120px; 
    height: 120px;
  }

  .text-section-content-construction {
    padding-left: 1rem;
    margin-top: 20px !important;
    margin-bottom: -20px;
  }

  .text-section-content-construction.h2 {
    font-size: 1.8rem;
  }

  .text-section-content-construction.p {
    font-size: 0.95rem;
  }
}


@media (max-width: 768px) {
  .image-text-section {
   
    margin-top: 15% !important;
  }

  .large-image {
    max-width: 100%; 
    height: 400px !important; 
  }

  .circular-image {
    width: 100px; 
    height: 100px;
    margin-bottom: 20px;
  }

  .text-section-content-construction.h2 {
    font-size: 1.6rem;
    
  }

  .text-section-content-construction.p {
    font-size: 0.7rem !important; 
  }

  .text-section-content-construction {
    padding-left: 0; 
    
  }
}


@media (max-width: 576px) {
  .large-image {
    max-width: 100%; 
    height: 350px !important; 
  }

  .text-section-content-construction {
    padding-left: 0;
  }

  .circular-image { 
    width: 90px;
    height: 90px;
  }

  .text-section-content-construction.h2 {
    font-size: 1.4rem;
  }

  .text-section-content-construction.p {
    font-size: 0.85rem; 
  }
}




.landing-page-construction {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden; 
  height: 100vh; 
  overflow: hidden; 
  display: flex;
  justify-content: center;
  align-items: center; 
  color: white;
  text-align: center; 
}

.landing-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
 
}
@media (max-width: 768px) {
  .landing-page {
    padding: 10px; 
  } 
}



.top-engineers-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}


.top-engineers-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.top-engineers-card__content {
  padding: 1rem;
  flex-grow: 1; 
}


.top-engineers-card__image-container {
  overflow: hidden;
  border-radius: 8px;
  height: 160px; 
  margin-bottom: 1rem;
  position: relative;
}

.top-engineers-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}


.top-engineers-card__name {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.top-engineers-card__location {
  font-size: 0.9rem;
  color: #6c757d;
}


.top-engineers-card__footer {
  padding: 1rem;
  border-top: none; 
  background-color: transparent; 
}


/* .top-engineers-card__footer .btn {
  display: block;
  width: 100%;
  text-align: center;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  
} */


.top-engineers-section {
  padding: 0 1rem; }
@media (max-width: 770px) and (min-width: 450px) {
  .top-engineers-card {
    flex-direction: column; 
    width: 100%; 
  }

  .top-engineers-card__image-container {
    height: 180px; 
    margin-bottom: 1rem; 
  }

  .top-engineers-card__name {
    font-size: 1.1rem; 
  }

  .top-engineers-card__location {
    font-size: 0.8rem; 
  }

  .top-engineers-card__footer {
    padding: 0.8rem;
  }

  .top-engineers-card__footer .btn {
    padding: 0.7rem 1.4rem;
  }
}


@media (max-width: 450px) {
  .top-engineers-card__image-container {
    height: 150px; 
  }

  .top-engineers-card__name {
    font-size: 1rem; 
  }

  .top-engineers-card__location {
    font-size: 0.7rem; 
  }

  .top-engineers-card__footer {
    padding: 0.6rem;
  }


}



  



  .faq-section-construction {
    max-width: 1400px;
    margin: 20px 20px;
    padding: 30px;
  }
  
  .faq-title-construction {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .faq-accordion-construction {
    margin-top: 20px;
  }
  
  .faq-card-construction {
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
  
  .faq-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    transition: background-color 0.3s ease;
  }
  
  .faq-question-construction {
    margin-left: 20px; 
    font-size: 1.1rem;
  }
  
  .faq-question-construction.text-style {
    color: #FE5D14;
  }
  
  .faq-toggle-icons {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .faq-body-construction {
    padding: 15px;
    font-size: 1rem;
    color: #333;
    border-top: 1px solid #e2e2e2;
  }
  
  .faq-body-construction p {
    margin: 0;
  }
  

  @media (max-width: 1200px) {
    .faq-section {
      margin: 50px 30px; 
      margin: 50px 30px; 
      padding: 20px;
    }
  
    .faq-title-construction {
      font-size: 1.75rem; 
    }
  
    .faq-question-construction {
      font-size: 1rem;
    }
  
    .faq-toggle-icons {
      font-size: 18px; 
      font-size: 18px; 
    }
  }
  
  @media (max-width: 768px) {
    .faq-section-construction {
      margin: 20px; 
      padding: 15px;
      justify-self: center;
    }
  
    .faq-title-construction {
      font-size: 1.5rem; 
    }
  
    .faq-question-construction {
      font-size: 1rem; 
      margin-left: 10px; 
    }
  
    .faq-toggle-icons {
      font-size: 18px;
    }
  
    .faq-body-construction {
      font-size: 0.95rem; 
    }
  }
  
  @media (max-width: 480px) {
    .faq-title-construction {
      font-size: 1.25rem;
    }
  
    .faq-question-construction {
      font-size: 0.95rem; 
    }
  
    .faq-body-construction {
      font-size: 0.9rem; 
    }
  }
  
  
  