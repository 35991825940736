:root {
  /* --primary-green2: #4fbb26; */
  --primary-green2: var(--primary-green2);
  /* --primary-gradient:var(--primary-gradient) */
}

@media (min-width: 992px) {
  .has-apps-sidebar .nk-sidebar {
    left: 0px !important;
  }
  .modal-xl {
    --bs-modal-width: 1200px !important;
  }
}

@media (max-width: 767px) {
  .heromanual-dropdown {
    width: 55% !important;
  }
}
.nk-content {
  margin-top: 60px;
}

.leaflet-popup-content {
  width: 400px !important;
  margin: 0px !important;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
}

.thumbnail-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.thumbnail-overlay img {
  display: block;
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  cursor: pointer;
}

.play-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 24px;
  border-color: transparent transparent transparent #ffffff;
}

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Adjust z-index to ensure it's above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-modal-content {
  position: relative;
  max-width: 800px; /* Adjust as necessary */
  width: 100%;
  max-height: 80vh; /* Adjust as necessary */
  background-color: #fff;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.video-player {
  position: relative;
  padding-top: 56.25%;
}

.video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-multi-range-slider .bar-inner {
  box-shadow: none !important;
  border: none;
}

.cardui {
  width: 260px;
  height: 300px;
  border-radius: 15px;
  /* background: linear-gradient(23deg, rgb(0, 0, 0) 0%, rgb(33, 99, 6) 35%, rgb(69, 231, 66) 100%); */
  /* background: var(--primary-gradient); */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.cardui::before {
  content: "";
  height: 100px;
  width: 100px;
  position: absolute;
  top: 85%;
  left: -20%;
  border-radius: 50%;
  border: 35px solid rgba(255, 255, 255, 0.102);
  transition: all 0.8s ease;
  filter: blur(0.1rem);
}

.text {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  color: aliceblue;
  font-weight: 400;
  font-size: 1.2em;
}

.iconsui {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 250px;
  border-radius: 0px 0px 15px 15px;
  overflow: hidden;
}

.cardui:hover::before {
  width: 200px;
  height: 200px;
  top: 30%;
  left: 50%;
  filter: blur(0rem);
}

.carduidjsection {
  width: 280px;
  height: 160px;
  border-radius: 5px;
  /* background: linear-gradient(23deg, rgb(0, 0, 0) 0%, rgb(33, 99, 6) 35%, rgb(69, 231, 66) 100%); */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.carduidjsection::before {
  content: "";
  height: 120px;
  width: 120px;
  position: absolute;
  top: -30%;
  left: -21%;
  border-radius: 50%;
  border: 35px solid rgba(255, 255, 255, 0.102);
  transition: all 0.8s ease;
  filter: blur(0.1rem);
}
.carduidjsection:hover::before {
  width: 200px;
  height: 200px;
  top: 20%;
  left: 50%;
  filter: blur(0rem);
}

.vidbutton {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: linear-gradient(30deg, rgb(255, 130, 0) 20%, rgb(255, 38, 0) 80%);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
  animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s normal none running pulse;
  align-items: center;
  border: 0;
}

.vidbutton:hover {
  transform: scale(1.2);
}

.carousel-control-prev,
.carousel-control-next {
  top: 80px !important;
  margin: 120px !important;
  background: #b5bac5 !important;
  width: 3.5% !important;
  height: 50px !important;
  border-radius: 50% !important;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(100%) !important;
}
.carousel-indicators button {
  width: 5px !important;
}
.bghover:hover {
  background-color: #e6eef5 !important;
  color: var(--primary-green2);
}

/* About - video slider CSS */
.swiper {
  width: 100%;
  /* height: 80vh; */
}

.swiper-slide {
  /* background: #000000; */
  display: flex;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper2 {
  width: 100%;
  height: 91vh;
}
.swiperss {
  height: 15vh !important;
  position: relative !important;
  bottom: 125px;
}

.swiperss .swiper-wrapper {
  height: 120px !important;
  justify-content: center;
}
.swiper2 .swiper-slidess {
  font-size: 18px;
  background: #ffffff;
  height: 150px;
  width: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  margin: 10px 0px;
}
.swiperss .swiper-slide-thumb-active {
  opacity: 1;
}
.swiper2 .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 0.5;
}
/* About Section Cards CSS */
.cardUI {
  width: 80%;
  height: 100%;
  border-radius: 15px;
  background: #f5f5f5;
  position: relative;
  border: 2px solid #c3c6ce;
  transition: 0.5s ease-out;
  overflow: visible;
}

.card-detailsUI {
  color: black;
  height: 100%;
  display: grid;
  place-content: center;
  padding-bottom: 2rem;
}

.card-buttonUI {
  transform: translate(-50%, 125%);
  width: 80%;
  border-radius: 1rem;
  border: none;
  /* background: linear-gradient(23deg, rgb(82, 224, 93) 0%, rgb(54, 186, 0) 35%, rgb(4, 28, 4) 100%); */
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
}
.card-buttonUI:hover {
  /* background: linear-gradient(23deg, rgb(82, 224, 93) 0%, rgb(54, 186, 0) 35%, rgb(4, 28, 4) 100%); */
  border-color:  var(--primary-green2);
}

/*Text*/
.text-titleUI {
  color: black;
}

/*Hover*/
.cardUI:hover {
  border-color: var(--primary-green2);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.cardUI:hover .card-buttonUI {
  transform: translate(-50%, 50%);
  opacity: 1;
}
.cardUI:hover .text-titleUI {
  color: var(--primary-green2);
}

/* Audio File CSS */
.cardaudio {
  position: relative;
  min-width: 250px;
  min-height: 130px;
  background: #191414;
  border-radius: 5px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0 10px 40px -25px rgba(100, 100, 100, 0.5);
}

.topaudio {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block-end: 0.5rem;
}

.pfp {
  position: relative;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-1audio {
  color: white;
  font-size: 1.25rem;
  font-weight: 900;
}

.title-2audio {
  color: white;
  font-size: 0.75rem;
  opacity: 0.8;
}

.playingaudio {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 1px;
  width: 30px;
  height: 20px;
}

.greenlineaudio {
  background-color:  var(--primary-green2);;
  height: 20px;
  width: 2px;
  position: relative;
  transform-origin: bottom;
}

.line-1audio {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.2s;
}

.line-2audio {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.5s;
}

.line-3audio {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.6s;
}

.line-4audio {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0s;
}

.line-5audio {
  animation: infinite playing 1s ease-in-out;
  animation-delay: 0.4s;
}

@keyframes playing {
  0% {
    transform: scaleY(0.1);
  }

  33% {
    transform: scaleY(0.6);
  }

  66% {
    transform: scaleY(0.9);
  }

  100% {
    transform: scaleY(0.1);
  }
}

/* Company Heading CSS DJ-Profile Page */
.cardCompanyTitle {
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* gap: 10px; */
  background-color: #c2c2c2;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.cardCompanyTitle::before {
  content: "";
  width: 150px;
  height: 100px;
  position: absolute;
  top: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 3px solid #fefefe;
  /* background: var(--primary-green2); */
  transition: all 0.3s ease;
}

.cardCompanyTitle * {
  z-index: 1;
}

/* .imageCompanyTitle {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 4px solid #fefefe;
  transition: all 0.5s ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

.card-infoCompanyTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
  transition: all 0.5s ease;
  color: white;
  margin-bottom: 5px;
  margin-top: 5px;
}

.card-infoCompanyTitle span {
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  line-height: 13px;
  margin: 0px 2px;
}

.pfpheader {
  position: relative;
  height: 40px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playingheader {
  display: flex;
  position: relative;
  justify-content: center;
  gap: 5px;
  width: 30px;
  height: 20px;
}

.greenlineheader {
  background-color: #ebebeb;
  height: 20px;
  width: 2px;
  position: relative;
  transform-origin: bottom;
}

/* Contact Form CSS */
.contactfrm {
  color: black;
  border-color: var(--primary-green2);
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

/* Footer css  */
.infoSection {
  color: #526484;
}

.infoSection:hover {
  color: var(--primary-green2);
}
.parentUI {
  display: flex;
  justify-content: center;
  align-items: center;
}

.childUI {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transition: all 0.3s cubic-bezier(0.68, 0.85, 0.265, 1.85);
  border-radius: 10px;
  /* margin: 0 5px; */
  box-shadow: inset 1px 1px 2px #fff, 0 0 5px #4442;
}

.childUI:hover {
  background-color: white;
  background-position: -100px 100px, -100px 100px;
  transform: perspective(180px) rotateX(60deg) translateY(2px);
}

.child-1UI:hover {
  box-shadow: 0px 10px 10px #316ff6;
}

.child-2UI:hover {
  box-shadow: 0px 10px 10px #e1306c;
}

.buttonUI {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 25px;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.85, 0.265, 1.55);
}

.childUI:hover > .buttonUI {
  transform: translate3d(0px, 20px, 30px) perspective(80px) rotateX(-60deg) translateY(2px) translateZ(10px);
}

.react-multiple-carousel__arrow--right {
  right: 0;
}
.react-multiple-carousel__arrow--left {
  left: 0;
}
.react-multiple-carousel__arrow {
  min-width: 36px;
  min-height: 36px;
  background: rgb(0 0 0 / 23%);
}
.player-wrapper {
  height: 200px;
  width: 100%;
}
.player-wrapper img {
  width: -webkit-fill-available;
  object-fit: cover;
}

/* responsive Social Media Icons */
.SMedia {
  width: 160px;
  position: relative;
  padding: 12px 0px;
  background: #fec195;
  font-size: 15px;
  color: #fff;
  border: 3px solid #fec195;
  border-radius: 19px;
  box-shadow: 0 0 0 #fec1958c;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.starSMedia-1 {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #405de6);
  z-index: -5;
  transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
}

.starSMedia-2 {
  position: absolute;
  top: 45%;
  left: 45%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.starSMedia-3 {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
}

.starSMedia-4 {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
}

.starSMedia-5 {
  position: absolute;
  top: 25%;
  left: 45%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
}

.starSMedia-6 {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 0.8s ease;
}
.starSMedia-7 {
  position: absolute;
  top: 40%;
  left: 35%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 0.8s ease;
}
.starSMedia-8 {
  position: absolute;
  top: 56%;
  left: 66%;
  width: 30px;
  height: auto;
  filter: drop-shadow(0 0 0 #fffdef);
  z-index: -5;
  transition: all 0.8s ease;
}

.SMedia:hover {
  background: transparent;
  color: #fec195;
  box-shadow: 0 0 25px #fec1958c;
}

.SMedia:hover .starSMedia-1 {
  position: absolute;
  top: -110%;
  left: 35%;
  width: 100px;
  height: auto;
  color: #405de6;

  z-index: 2;
  text-align: start;
}

.SMedia:hover .starSMedia-2 {
  position: absolute;
  top: -95%;
  left: -15%;
  width: 100px;
  height: auto;
  color: #316ff6;

  z-index: 2;
}

.SMedia:hover .starSMedia-3 {
  position: absolute;
  top: -60%;
  left: -35%;
  width: 100px;
  height: auto;
  color: #e1306c;

  z-index: 2;
}

.SMedia:hover .starSMedia-4 {
  position: absolute;
  top: -5%;
  left: -50%;
  width: 100px;
  height: auto;
  color: #010101;

  z-index: 2;
}

.SMedia:hover .starSMedia-5 {
  position: absolute;
  top: 66%;
  left: -50%;
  width: 100px;
  height: auto;
  color: #0072b1;

  z-index: 2;
}

.SMedia:hover .starSMedia-6 {
  position: absolute;
  top: 120%;
  left: -35%;
  width: 100px;
  height: auto;
  color: #ff5500;

  z-index: 2;
}
.SMedia:hover .starSMedia-7 {
  position: absolute;
  top: 160%;
  left: -20%;
  width: 100px;
  height: auto;
  color: #52aad8;

  z-index: 2;
}
.SMedia:hover .starSMedia-8 {
  position: absolute;
  top: 200%;
  left: -5%;
  width: 100px;
  height: auto;
  color: #6441a4;

  z-index: 2;
}

.fil0 {
  fill: #fffdef;
}

/* Package UI CSS */

.package-cardUI {
  height: 320px;
  perspective: 1000px;
  font-family: sans-serif;
}

.package-card-innerUI {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.package-cardUI:hover .package-card-innerUI {
  transform: rotateY(180deg);
}

.package-card-frontUI,
.package-card-backUI {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.package-card-backUI {
  transform: rotateY(180deg);
}

.custom-rounded-top {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.modal-body {
  /* max-height: 100vh; */
  /* overflow-y: auto; */
}
@media (max-width: 997px) {
  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.card---container {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0 0;
}

.card--- {
  flex: 2;
  background-color: #d9e2e9;
  border-radius: 9px;
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 50px;
  min-width: 120px;
  max-width: 150px;
  margin: 5px;
}

.card---:hover {
  transform: scale(1.04);
  cursor: pointer;
}

.card---cover {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: #0b0b0b;
  margin-left: 0;
}

.card---title {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.badge---card {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.card---img {
  height: 300px !important;
}

.card---badge {
  height: 50px;
  width: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .badge---card {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .card--- {
    width: 100%;
  }

  .card--- {
    width: 100%;
    height: auto;
  }

  .form-check-label {
    font-size: 1rem;
  }
}

@media (min-width: 769px) {
  .badge---card {
    gap: 20px;
  }

  .card--- {
    height: 60px;
  }

  .form-check-label {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .card---container {
    flex-direction: row;
  }

  .card---cover {
    justify-content: flex-start;
    margin-left: 20px;
    text-align: left;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .card---cover {
    margin-left: 15px;
  }
}

/* Review Star Rating CSS */
.ratingUI {
  display: inline-block;
}

.ratingUI input {
  display: none;
}

.ratingUI label {
  float: right;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
}

.ratingUI label:before {
  content: "\2605";
  font-size: 30px;
}

.ratingUI input:checked ~ label,
.ratingUI label:hover,
.ratingUI label:hover ~ label {
  color: #f4bd0e;
  transition: color 0.3s;
}

/* Calendar Button UI style={{ height: "230px", overflowY: "auto" }} */
.slider-container {
  height: 230px;
  overflow-y: auto;
}

@media (max-width: 997px) {
  .slider-container {
    height: 290px;
    overflow-y: auto;
  }
}

.BtnCalendarUI {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--primary-green2);
}
.signCalendarUI {
  width: 100%;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textCalendarUI {
  position: absolute;
  right: 0%;
  width: 100%;
  opacity: 0;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  transition-duration: 0.5s;
}
.BtnCalendarUI:hover {
  /* width: 120px; */
  width: 150px;

  border-radius: 40px;
  transition-duration: 0.5s;
}
.BtnCalendarUI:hover .signCalendarUI {
  width: 20%;
  transition-duration: 0.5s;
}
.BtnCalendarUI:hover .textCalendarUI {
  opacity: 1;
  width: 90%;
  transition-duration: 0.7s;
  text-align: center;
}
.BtnCalendarUI:active {
  transform: translate(2px, 2px);
}

/* DJ Enquiry CSS  */
.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.country-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.selected-logo {
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.country-logo-business-portal {
  width: 15px !important;
  height: 15px !important;
  margin-right: 2px;
}

.selected-logo-business-portal {
  width: 20px !important;
  height: 20px !important;
  margin-right: 3px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  border-radius: 50%;
  padding: 25px;
  font-size: 5px !important;
}

:root {
  --swiper-navigation-size: 30px !important;
}

.swiper-button-next:hover {
  background: #424443;
  padding: 25px;
  border-radius: 50%;
  color: #fff;
}
.swiper-button-prev:hover {
  background: #424443;
  padding: 25px;
  border-radius: 50%;
  color: #fff;
}

/* App.css */

.large-icon {
  font-size: 10rem; /* Adjust this size as needed */
}

.small-icon {
  font-size: 3rem;
}
.icon-fix {
  font-size: 2rem;
}
/* .loader-spinner-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .loader-spinner-custom {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  background-color: rgba(255, 255, 255, 0.9); 
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 20px;
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
} */

/* .flipbook-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  perspective: 2000px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  margin-bottom: 70px;
} */

/* .flipbook {  
  background-image: url(../assets/images/images.jpeg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  
 
  }  */
.icons {
  /* margin-left: 6rem; */
  height: 50px;
  width: 74rem;
  font-size: 30px;
  color: white;
  gap: 70px;
}
.zoom {
  bottom: 190px;
}

.zoom-out {
  bottom: 120px;
}

.icon-color {
  color: #0b0b0b; /* Change this to the desired color */
  cursor: pointer;
}

/* Default dark color for the .fc-dayGridMonth-button */
.fc-dayGridMonth-button {
  background-color: #333 !important; /* Dark background */
  color: white !important; /* White text */
}

/* Dark color for active buttons */
.fc-timeGridWeek-button.active,
.fc-timeGridDay-button.active,
.fc-listWeek-button.active,
.fc-dayGridMonth-button.active {
  background-color: #333 !important; /* Dark background */
  color: white !important; /* White text */
}

/* Light color for inactive buttons */
.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listWeek-button,
.fc-dayGridMonth-button {
  background-color: #f1f1f1 !important; /* Light background for inactive buttons */
  color: #333 !important; /* Dark text */
}

/* Hover effect */
.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover,
.fc-listWeek-button:hover,
.fc-dayGridMonth-button:hover {
  background-color: #ddd !important; /* Lighter background on hover */
}

/* SpeedoMeter CSS */

.speedo-wrap {
  display: flex;
  justify-content: center;
  height: 1rem;
  font-size: 1rem;
  line-height: 1rem;
  overflow: hidden;
}

.speedo-digit {
  transition: 1s all;
}

@media (max-width: 1024px) {
  .double-page-container {
    flex-direction: column; /* Stack pages vertically on tablets */
  }

  .page-left,
  .page-right {
    width: 100%; /* Full width on tablets */
  }

  .icons {
    width: 100%; /* Make icons fit on smaller screens */
    gap: 30px; /* Adjust gap for smaller screens */
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  .zoom {
    bottom: 150px; /* Adjust position for tablets */
  }

  .zoom-out {
    bottom: 100px; /* Adjust position for tablets */
  }
}
/* General Flipbook Styles */
.flipbook {
  gap: 10px;

  background-color: #f4f0f0; /* Example background */
  padding: 10px;
}
.flipbookpdf {
  /* height: 690px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #f4f0f0; /* Example background */
  padding: 10px;
}

/* Flipbook Wrapper */
/* .flipbook-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
} */
.flipbook-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the flipbook horizontally */
  justify-content: center; /* Centers the flipbook vertically */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Ensure no padding affects the layout */
  /* Adjust to fit within the viewport */
  /* box-sizing: border-box; Ensures padding and borders don’t affect size */
}

.flipbookpdf {
  overflow: hidden; /* Prevents scrollbars if the flipbook size adjusts */
}

/* Sidebar Styles */
.flipbookpdf .relative .fixed {
  width: 250px;
}
@media (max-width: 450px) {
  .flipbookpdf {
    height: 450px;
  }
}
/* @media (max-width: 768px) {
  .flipbookpdf {
    height: 580px;
  }
} */
@media (min-width: 451px) and (max-width: 768px) {
  .flipbookpdf {
    height: 580px;
  }
}

@media (max-width: 768px) {
  /* Adjust Flipbook for Tablets */

  .flipbook-wrapper {
    transform: scale(0.8); /* Adjust scale */
    margin: 0 auto; /* Center on smaller screens */
  }

  .flipbook-page img {
    width: 100%;
    height: auto;
  }

  .relative .fixed {
    width: 200px;
  }

  .icons {
    flex-wrap: wrap; /* Allow icons to wrap on smaller screens */
    justify-content: center;
  }

  .icons svg {
    margin: 5px; /* Adjust spacing for icons */
  }
}

@media (max-width: 480px) {
  /* Adjust Flipbook for Mobile */
  .flipbook-wrapper {
    transform: scale(0.6); /* Further adjust scale */
  }

  .relative .fixed {
    width: 150px;
  }

  .icons svg {
    width: 20px;
    height: 20px; /* Smaller icons for mobile */
    margin: 4px;
  }

  .flipbookpdf {
    gap: 5px; /* Smaller gap between elements */
  }
}
@media screen and (max-width: 600px) {
  .carousel-item {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.availabel-slot-frontUI {
  /* cursor: pointer; */
  /* border: 2px solid  var(--primary-green2); */
  border: 2px solid  #d30d24;
}
/* .availabel-slot-frontUI:active {
  cursor: pointer;
  border: 2px solid #e86b6b;
} */

.responsive-text {
  top: 100px;
}
.responsive-font {
  font-size: 20px;
}

.responsive-fonttext {
  font-size: 15px;
}
.responsive-title {
  font-size: 30px;
}

@media (max-width: 574px) {
  .responsive-text {
    text-size-adjust: 20px;
    top: 25px;
  }
  .responsive-font {
    font-size: 10px;
  }
  .responsive-fonttext {
    font-size: 10px;
  }
}

@media (max-width: 376px) {
  .responsive-text {
    text-size-adjust: 10px;
    top: 25px;
  }
  .responsive-font {
    font-size: 10px;
  }
  .responsive-fonttext {
    font-size: 5px;
  }
}

@media (max-width: 1130px) {
  .responsive-text {
    text-size-adjust: 20px;
    top: 40px;
  }
  .responsive-font {
    font-size: 17px;
  }
  .responsive-fonttext {
    font-size: 20px;
  }
}

@media (max-width: 1270px) {
  .responsive-text {
    text-size-adjust: 20px;
    top: 80px;
  }
  .responsive-font {
    font-size: 17px;
  }
  .responsive-fonttext {
    font-size: 11px;
  }
}

@media (max-width: 376px) {
  .responsive-text {
    text-size-adjust: 20px;
    top: 2px;
  }
  .responsive-font {
    font-size: 15px;
  }
  .responsive-fonttext {
    font-size: 10px;
  }
}

/* styling for 3d-viewer */
.card-3d {
  display: flex;
  flex-direction: column;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: 20px;
}

.image-container {
  width: 100%;
  height: 150px;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-container {
  padding: 10px;
  text-align: center;
}

.card-title-3d {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

/* Base styles for the modal */
.modal-dialog {
  max-width: 90%; /* Default for small screens */
  max-height: 90%; /* Default for small screens */
}

.modal-content {
  border-radius: 8px;
  overflow: hidden;
}

/* Responsive styles */
@media (min-width: 768px) {
  /* Tablet and larger */
  .modal-dialog {
    max-width: 70%;
    max-height: 70%;
  }
}

@media (min-width: 1024px) {
  /* Laptop and larger */
  .modal-dialog {
    max-width: 50%;
    max-height: 50%;
  }
}

.canvas-container {
  height: 250px; /* Default height for smaller screens */
  width: 100%; /* Full width */
}

@media (min-width: 768px) {
  /* Tablet and larger */
  .canvas-container {
    height: 400px;
  }
}

@media (min-width: 1024px) {
  /* Laptop and larger */
  .canvas-container {
    height: 400px;
  }
}
.hovereffct:hover {
  background-color:  var(--primary-green2);
  color: #000000;
}
.hovereffct {
  background-color: #e1e1e1;
  color: #000000 !important ;
}

.disclaimer {
  display: flex;
  align-items: center;
  background-color: #fdfd96; /* Light gray background */
  border: 1px solid #d6d6d6; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  padding: 8px 12px;
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #333; /* Dark text color */
}

.disclaimer span {
  margin-left: 8px; /* Space between the icon and the text */
  font-weight: 500; /* Slightly bolder text */
}

.disclaimer .icon {
  color: #007bffa1; /* Color for the icon */
  margin-right: 6px; /* Space between the icon and the text */
}

/* .tooltip-hover {
  display: none;
}

.position-relative:hover .tooltip-hover {
  display: block;
} */

.CircularProgressbar-text {
  transform: translate(-11px, 5px);
}

.BtnpdfUI {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: #bb2626bf;
}
.signpdfUI {
  width: 100%;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textpdfUI {
  position: absolute;
  right: 0%;
  width: 100%;
  opacity: 0;
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  transition-duration: 0.5s;
}
.BtnpdfUI:hover {
  width: 120px;
  border-radius: 40px;
  transition-duration: 0.5s;
}
.BtnpdfUI:hover .signpdfUI {
  width: 20%;
  transition-duration: 0.5s;
}
.BtnpdfUI:hover .textpdfUI {
  opacity: 1;
  width: 90%;
  transition-duration: 0.7s;
  text-align: center;
}
.BtnpdfUI:active {
  transform: translate(2px, 2px);
}

.djslide .swiper-button-next,.djslide .swiper-button-prev{
  transition: all ease 0.5s;
  background: rgba(0, 0, 0, 0.5)
}
.djslide .swiper-button-next:hover,.djslide .swiper-button-prev:hover{
  background: rgba(0, 0, 0, 0.853)
}
.djslide .swiper-wrapper {
  height: 100vh;
}


.profile-chat-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between items */
  width: 100%; /* Ensures it takes up the full width */
}

.profile-chat-container span {
  flex-grow: 1; /* Allows the company name to take up available space */
  margin-right: 10px; /* Optional: for space between the company name and chat button */
}

.custom-chat-button {
  display: flex;
  justify-content: center; /* Ensures the button is centered inside the container */
  align-items: center;
  padding: 10px; /* Optional: for additional spacing */
  margin-left: auto; /* Pushes the chat button to the right */
}

.chat-btn {
  width: 50px; /* Default size */
  height: 50px; /* Default size */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-green2);
  color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 20px; /* Default font size */
  transition: all 0.3s ease;
}

.chat-btn:hover {
  background-color: var(--primary-green2);
  transform: scale(1.1);
}

.chat-btn:focus {
  outline: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .chat-btn {
    width: 40px; /* Smaller size for tablets and mobile */
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .chat-btn {
    width: 35px; /* Even smaller size for small screens */
    height: 35px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .recaptcha-responsive {
    transform: scale(0.85); /* Scale down the size for mobile screens */
    transform-origin: center; /* Ensure it scales from the center */
  }
}
