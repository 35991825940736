.builders-section-construction {
  margin-top: 0;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 60px;
}

.left-image-construction {
  height: 560px !important;
  width: 100%;
  object-fit: cover;
  max-height: 600px;
  margin-top: 60px;
  border-radius: 20px;
}

.right-content-construction {
  margin-top: 4rem;
}

/* .right-content-construction h2 {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 2rem;
} */

/* .right-content-construction p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
} */

.text-style-construction {
  color: #FE5D14; 
}

.bottom-image-construction {
  border: 1px solid #ddd;
  height: auto;
  object-fit: cover;
  width: 100%;
  max-width: 700px;
  max-height: 320px !important;
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .builders-section-construction {
    margin: 0 5vw 60px;
  }

  .left-image-construction {
    max-height: 500px !important;
  }

  /* .right-content-construction h2 {
    font-size: 1.8rem;
  } */

  /* .right-content-construction p {
    font-size: 0.95rem;
  } */

  .bottom-image-construction {
    max-height: 400px !important;
    width: 100%;
    border-radius: 15px;
  }
}


@media (min-width: 760px) and (max-width: 1000px) {
  .builders-section-construction {
    margin: 0 5vw 60px; 
    padding-top: 3rem;
  }

  .left-image-construction {
    max-height: 500px !important;
    margin-top: 2rem;
  }

  .right-content-construction {
    margin-top: 0; 
  }

  /* .right-content-construction h2 {
    font-size: 1.8rem;
  }

  .right-content-construction p {
    font-size: 1rem;
  } */

  .bottom-image-construction {
    max-height: 400px !important;
    width: 100%;
    border-radius: 15px;
  }
}


@media (max-width: 992px) {
  .builders-section-construction {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 0 !important; 
  }

  .left-image-construction {
    max-height: 500px !important;
    border-radius: 15px;
    object-fit: cover !important;
  }

  .right-content-construction {
    margin-top: 0; 
  }

  /* .right-content-construction h2 {
    font-size: 1.6rem;
    text-align: center;
  }

  .right-content-construction p {
    font-size: 0.9rem;
    line-height: 1.5;
    text-align: justify;
  } */

  .bottom-image-construction {
    max-height: 400px !important;
    width: 100%;
    border-radius: 15px;
    object-fit: cover !important;
  }
}


@media (max-width: 768px) {
  .builders-section-construction {
    margin: 0 2vw 60px;
    gap: 1.5rem;
    padding: 1rem;
  }

  .left-image-construction {
    max-height: 480px !important;
  }

  /* .right-content-construction h2 {
    font-size: 1.4rem;
  }

  .right-content-construction p {
    font-size: 0.85rem;
  } */

  .bottom-image-construction {
    max-height: 430px !important;
    border-radius: 10px;
  }
}


@media (max-width: 480px) {
  .builders-section-construction {
    margin: 0 2vw 60px;
    gap: 1.2rem;
    padding: 1rem;
  }

  .left-image-construction {
    max-height: 400px !important;
  }

  /* .right-content-construction h2 {
    font-size: 1.2rem;
  }

  .right-content-construction p {
    font-size: 0.8rem;
  } */

  .bottom-image-construction {
    max-height: 400px !important;
    border-radius: 8px;
  }
}


  
 
  .features-section-construction {
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  /* .features-section-construction h2 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 1.8em;
    text-align: center;
  }
  
  .features-section-construction p {
    text-align: center;
    font-size: 1em;
    margin-bottom: 30px;
  } */
  
  .feature-construction-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    min-height: 270px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  
  .icon-wrapper-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px; 
    margin-bottom: 8px;
    margin-top: -30px !important; 
  }
  
  .icon-wrapper-construction i {
    font-size: 3.5em; 
    color: #fe5d14;
    
  }
  
  .feature-construction-card h5 {
    font-size: 1.4em; 
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px; 
  }
  
  .feature-construction-card p {
    font-size: 1em; 
    text-align: center;
    color: #6c757d;
    margin-bottom: 0;
  } 
  
  @media (max-width: 1024px) {
    .features-section-construction {
      padding: 20px;
    }
  
    .icon-wrapper-construction i {
      font-size: 3.2em; 
    }
  }
  
  @media (max-width: 768px) {
    /* .features-section-construction h2 {
      font-size: 1.5em;
    } */
  
    .feature-construction-card {
      min-height: 240px;
    }
  }
  
  @media (max-width: 480px) {
    /* .features-section-construction h2 {
      font-size: 1.3em;
    } */
  
    .feature-construction-card {
      min-height: 220px;
    }
  
    .icon-wrapper-construction i {
      font-size: 2.8em;
    }
  }
  
 










  
  
 
  .offer-section-construction {
    margin: 10px 20px; 
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  } 
  
  .offer-icon {
    font-size: 28px; 
    color: #FE5D15 !important;  
  }
  
  .offer-list {
    margin-top: 10px;
  }
  
  .construction-offer-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .construction-offer-title {
    font-size: 1rem;
   
    color: #FE5D15 !important;
    
  }
  
  .construction-offer-item i.offer-icon {
    margin-right: 10px;
  }
  
 
  .construction-offer-item .ms-3 {
    margin-left: 15px;
  } 
  
  
  /* .py-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  } */
  
 
  @media (max-width: 768px) {
    .offer-section-construction {
      margin: 0px ;
      margin-bottom: 10px; 
      margin-bottom: 10px; 
    }
  
    /* .section-title {
      font-size: 1.5rem; 
      font-size: 1.5rem; 
    } */
  
    .construction-offer-item {
      flex-direction: row; 
      align-items: center; 
      flex-direction: row; 
      align-items: center; 
    }
  
    .construction-offer-title {
      font-size: 1.1rem; 
      font-size: 1.1rem; 
    }
  
    .offer-image {
      min-height: 300px;  
      min-height: 300px;  
    } 
  }
   
  




  
  .reviews-section-construction {
    margin-top: 10px !important;
    height: auto; 
    padding: 20px; 
    overflow: hidden; 
  }
  
  .review-construction-card {
    position: relative; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    padding: 20px;
    border-radius: 10px; 
    border: 1px solid #131944; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    background-color: white; 
  }
  
  .review-text-construction {
    margin-top: 10px !important;
    text-align: center;
    font-size: 1rem; 
    line-height: 1.5;
  }
  
  /* .review-construction-card img {
    object-fit: cover;
    border: 3px solid #ddd;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1rem;
  } */
  
  .ratings-construction {
    text-align: center;
    font-size: calc(1rem + 0.5vw); 
  }
  
  .gold-stars-construction {
    color: gold;
  }
  
  .gray-stars-construction {
    color: lightgray;
  }
  
  
  @media (min-width: 576px) {
    .reviews-section-construction {
      margin-top: 10px !important;
    }
    .review-construction-card {
      flex-direction: row;
      align-items: flex-start; 
      height: auto;
      padding: 15px;
    }
    /* .review-construction-card img {
      margin-right: 1rem;
    } */
    .review-text-construction {
      text-align: left;
    }
  }
  
  
  @media (max-width: 575px) {
    .review-construction-card {
      display: block;
      text-align: center; 
      padding: 10px;
    }
    /* .review-construction-card img {
      margin: 0 auto 1rem; 
    } */
    .review-text-construction {
      text-align: center; 
      margin: 10px 0;
    }
    .ratings-construction {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
  
  
  .navigation-buttons-construction {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 1.5rem;
  }
  
  .navigation-buttons-construction button {
    width: 50px;
    height: 50px;
  
  }
  
  /* .navigation-buttons-construction button:hover {
    background-color: #ff6d2a;
  }
   */
  .navigation-buttons-construction .arrow-construction {
    font-weight: bold;
    font-size: 30px;
    color: white;
  }
  
  @media (max-width: 767px) {
    .navigation-buttons-construction button {
      width: 45px;
      height: 45px;
      font-size: 25px;
    }
  }
  
  
  @media (max-width: 400px) { 
    .review-construction-card {
      padding: 10px;
    }
    .review-text-construction {
      font-size: 0.9rem;
    }
    .navigation-buttons-construction button {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
  }
  

  


 
 /* General Styles */
.construction-join-us-section {
  padding: 20px;
  background-color: #f7f7f7;
}

.construction-join-us-header {
  font-size: 2rem;
  color: #333;
}

.construction-join-us-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Adds spacing between cards */
  margin-top: 20px;
}

.construction-join-us-card {
  display: flex;
  flex-direction: row;
  width: 48%; /* Ensure cards are in one row */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.construction-join-us-card-left,
.construction-join-us-card-right {
  padding: 20px;
}

.construction-join-us-card-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.construction-join-us-card-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.construction-join-us-card-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.construction-join-us-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #444;
}

.construction-join-us-card-description {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.construction-join-us-nav-link {
  text-decoration: none;
}

.construction-btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.construction-btn-primary:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .construction-join-us-header {
    font-size: 1.5rem;
  }

  .construction-join-us-cards {
    flex-direction: column;
    align-items: center;
  }

  .construction-join-us-card {
    flex-direction: column;
    width: 100%;
  }

  .construction-join-us-card-left,
  .construction-join-us-card-right {
    width: 100%;
  }

  .construction-join-us-card-title {
    font-size: 1.2rem;
  }

  .construction-join-us-card-description {
    font-size: 0.9rem;
  }

}

@media (max-width: 480px) {
  .construction-join-us-header {
    font-size: 1.2rem;
  }

  .construction-join-us-card {
    flex-direction: column;
    width: 100%;
  }

  .construction-join-us-card-title {
    font-size: 1rem;
  }

  .construction-join-us-card-description {
    font-size: 0.8rem;
  }

  
}
/* Responsive Design for screen widths between 760px and 1200px */
@media (min-width: 760px) and (max-width: 1200px) {
  .construction-join-us-cards {
    flex-direction: column; /* Stack the cards vertically */
    align-items: center; /* Center the cards */
  }

  .construction-join-us-card {
    flex-direction: column; /* Stack the content vertically */
    width: 100%; /* Ensure the cards take full width */
  }

  .construction-join-us-card-left,
  .construction-join-us-card-right {
    width: 100%; /* Both sections should take full width */
  }

  .construction-join-us-card-left {
    order: 1; /* Move the image section to the top */
  }

  .construction-join-us-card-right {
    order: 2; /* Keep the content below the image */
  }

  .construction-join-us-card-image {
    width: 100%; /* Ensure image takes full width */
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 20px; /* Add some space between image and text */
  }

  .construction-join-us-card-title {
    font-size: 1.5rem; /* Adjust title size */
    margin-bottom: 10px; /* Space between title and description */
  }

  .construction-join-us-card-description {
    font-size: 1.2rem; /* Adjust description size */
    margin-bottom: 20px; /* Space between description and button */
  }

  .construction-btn-primary {
    font-size: 1rem; /* Adjust button font size */
    padding: 10px 20px; /* Adjust button padding */
  }
}

