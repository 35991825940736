:root {
  /* --primary-green3: orange; */
  /* --primary-green2:#1c6098 ; */
  --primary-green2: var(--primary-green2);
}


@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* styles.css */
body {
  overflow-y: scroll;
  background-color: #f5faf6 !important;
  font-family: "Montserrat", sans-serif !important;
}

* {
  box-sizing: border-box;
}

/* font-css (Brand-Guidelines) */
.nav-tabs .nav-item {
  font-family: "Montserrat", sans-serif !important;
}

.btn {
  font-family: "Montserrat", sans-serif !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "Montserrat", sans-serif !important;
}
.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  background: var(--primary-green2) !important;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: your-colors-here;
}

/* WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: thin;
}

/* updated css (Brand-Guidelines) */
.apps-menu .nk-menu-item:hover em {
  color: var(--primary-green);
}

.apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  color: var(--primary-green) !important;
}

.apps-menu .nk-menu-item.active {
  background-color: #eeffeb !important;
}

.apps-menu .nk-menu-item:hover {
  background-color: #eeffeb;
}

.dark-mode .apps-menu .nk-menu-item:hover em {
  color: var(--primary-green) !important;
}

.dark-mode .nk-menu-icon .icon {
  color: #c3eeeb !important;
}

.dark-mode .apps-menu .nk-menu-item:hover {
  background-color: #2c3e50 !important;
}

.dark-mode .apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  color: var(--primary-green) !important;
}

.dark-mode .apps-menu .nk-menu-item.active {
  background-color: #2c3e50 !important;
}

.form-check-input:checked {
  background-color: var(--primary-green) !important;
  border-color: var(--primary-green) !important;
}
.form-check-input {
  background-color: #ffffff !important;
  border-color: #727272 !important;
}

.dropdown-menu-s1 {
  border-top-color: #f5faf6 !important;
}
