 .blog-container-construction {
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.construction-carousel-container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  object-fit: cover;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.construction-content {
  max-width: 90%;
  margin-top: 5rem;
}

.construction-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: bold;
}

.construction-description {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 0;
}

.construction-footer-info {
  position: absolute;
  bottom: 50px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  z-index: 2;
}

.construction-author-info {
  display: flex;
  align-items: center;
}

.construction-author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.construction-author-details {
  display: flex;
  flex-direction: column;
}

.construction-author-name {
  font-weight: bold;
}

.publish-date {
  font-size: 0.85rem;
  color: #ccc;
}

.construction-controls {
  position: absolute;
  bottom: 120px; /* Adjusted for better visibility */
  left: 10%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.construction-dot {
  width: 10px !important;
  height: 10px !important;
  background: #fff;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s ease;
}

.construction-dot.active {
  opacity: 1;
  width: 10px;
  height: 10px;
  background: #FE5D14;
  transform: scale(1.2);
}

.construction-dot:hover {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .construction-carousel-container {
    height: 90vh !important;
  }

  .overlay {
    padding: 3%;
  }

  .construction-content {
    max-width: 95%;
    margin-top: 1rem;
  }

  .construction-title {
    font-size: 1.8rem;
  }

  .construction-description {
    font-size: 0.85rem;
  }

  .construction-footer-info {
    bottom: 15px;
    right: 15px;
    padding: 8px 12px;
  }

  .construction-author-image {
    width: 35px;
    height: 35px;
  }

  .construction-controls {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .construction-carousel-container {
    height: 70vh;
  }

  .construction-title {
    font-size: 1.5rem;
  }

  .construction-description {
    font-size: 0.75rem;
  }

  .construction-footer-info {
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
  }

  .construction-author-image {
    width: 30px;
    height: 30px;
  }

  .construction-controls {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    gap: 5px;
  }

  .construction-dot {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 480px) {
  .construction-carousel-container {
    height: 60vh;
  }

  .construction-title {
    font-size: 1.4rem;
  }

  .construction-description {
    font-size: 0.7rem;
  }

  .construction-footer-info {
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
  }

  .construction-author-image {
    width: 25px;
    height: 25px;
  }

  .construction-controls {
    bottom: 50px !important;
    left: 50%;
    transform: translateX(-50%);
    gap: 5px;
  }

  .construction-dot {
    width: 6px;
    height: 6px;
  }
}

  










  .construction-cards-section {
    padding: 2rem 0;
  }
  
  .construction-card {
    border: none;
    margin-bottom: 1.5rem; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }
  
  .construction-card-img-top {
    width: 100%;
    height: auto;
    object-fit: cover; 
  }
  
  
  .construction-card-title {
    font-size: 1.1rem !important; 
    font-weight: bold;
    color: black !important;
    margin-top: 20px !important;
  }
  
  
  
  .construction-read-more {
    color: #FE5D14;
    text-decoration: none;
    font-weight: bold;
  }
  
  .construction-read-more:hover {

    color: #FE5D14;
  }
  
  .construction-card-footer {
    background-color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
  }
  
  .construction-card-footer img {
    width: 50px;
    height: 50px;
    border-radius: 50%; 
    object-fit: cover;
  }
  
  .construction-card-footer span {
    color: #555;
  }
  
 
  @media (max-width: 1024px) {
    .construction-cards-section {
      padding: 2rem 1rem; 
    }
  
    .construction-card-title {
      font-size: 1.1rem;
    }
  
    .construction-card-text {
      font-size: 0.95rem;
    }
  
    .construction-card-footer {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 1024px) {
    .construction-card-img-top {
      width: 100%; 
      height: 250px; 
    }
  }
  
  @media (max-width: 768px) {
    .construction-card-img-top {
      width: 100%; 
      height: 200px; 
    }
  }
  
  @media (max-width: 480px) {
    .construction-card-img-top {
      width: 100%;
      height: 150px; 
    }
  }
  
  
  @media (max-width: 768px) {
    .construction-cards-section {
      padding: 1.5rem 1rem; 
    }
  
    .construction-card {
      flex-direction: column;
      padding: 1rem;
    }
  
    .construction-card-title {
      font-size: 1rem; 
    }
  
    .construction-card-text {
      font-size: 0.9rem;
    }
  
    .construction-card-footer {
      font-size: 0.8rem;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .construction-card-footer img {
      width: 40px;
      height: 40px;
    }
  }
  
  @media (max-width: 480px) {
    .construction-cards-section {
      padding: 1rem 0.5rem; 
    }
  
    .construction-card-title {
      font-size: 1rem;
    }
  
    .construction-card-text {
      font-size: 0.85rem;
    }
  
    .construction-card-footer {
      font-size: 0.75rem;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5rem;
    }
  
    /* .construction-card-footer img {
      width: 35px;
      height: 35px;
    } */
  
  
    .construction-read-more {
      font-size: 0.85rem; 
    }
  }
  


  











  .read-more-page {
    margin-top: 0;
    margin-left: 50px;
    margin-right: 50px;
  }
  
  .read-more-page .lead {
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .read-more-page h1 {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }
  
  .bg-style {
    background-color: #FE5D14;
  }
  
  .text-tyle {
    color: #FE5D14;
  } 
  
 
  @media (max-width: 576px) {
    .read-more-page {
      margin-left: 15px;
      margin-right: 15px;
    }
  
    .read-more-page h1 {
      font-size: 1.8rem; 
    }
  
    .read-more-page .lead {
      font-size: 1rem; 
    }
  
    .col-md-8, .col-md-4 {
      padding-left: 0;
      padding-right: 0;
    }
  
    .text-center {
      text-align: center;
    }
  
    .read-more-page img {
      width: 100%; 
      height: auto;
    }
  } 
  
  
  @media (max-width: 768px) {
    .read-more-page {
      margin-left: 30px;
      margin-right: 30px;
    }
  
    .read-more-page h1 {
      font-size: 2rem; 
    }
  
    .read-more-page .lead {
      font-size: 1.05rem;
    }
  
    .read-more-page img {
      width: 80%; 
      height: auto;
    }
  
    .col-md-4 {
      margin-top: 20px; 
    }
  } 
  
  
  @media (min-width: 992px) {
    .read-more-page {
      margin-left: 50px;
      margin-right: 50px;
    }
  
    .read-more-page h1 {
      font-size: 2.5rem;
    }
  
    .read-more-page .lead {
      font-size: 1.1rem;
    }
  
    .read-more-page img {
      width: 100%;
      height: auto;
    }
  } 
  