:root{
  /* --primary-green2:#1c6098 ; */
}
/* NewHero Styles */
.new-hero {
  position: relative;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-size: cover;
  background-position: center;
  background-image: url('../ImagesPhotography/newhero.webp');
}
.new-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.new-hero-quote {
  position: absolute;
  top: 10%;
  left: 3%;
  transform: translateX(-100%);
  opacity: 0;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px 15px;
  letter-spacing: 4px;
  transition: all 1s ease-out;
}

.new-hero-quote2 {
  position: absolute;
  top: 15%;
  left: 12%;
  transform: translateX(-100%);
  opacity: 0;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px 15px;
  letter-spacing: 4px;
  transition: all 1s ease-out;
}

.new-hero-right-section {
  position: relative;
  z-index: 2;
  text-align: center;
  padding-right: 3rem;
  transform: translateY(100%);
  opacity: 0;
  transition: all 1.5s ease-out;
  margin-top: -5rem;
}

.new-hero-title {
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  color: #fff;
  font-family: 'Lora', serif;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
  margin-left: 12rem;
}

.new-hero-description {
  font-size: 1rem;
  font-weight: 500;
  color: #ddd;
  margin-bottom: 1.5rem;
  line-height: 1.8;
  letter-spacing: 0.5px;
  margin-left: 12rem;
}

.new-hero-button {
  font-size: 16px;
  padding: 12px 24px;
  /* background: linear-gradient(45deg, #1c6098, #4a90e2); */
  border: none;
  color: #fff;
  border-radius: 30px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  margin-left: 12rem;
}

.new-hero-button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
}

.new-hero-search-bar {
  /* margin-top: -2rem; */
  margin-top: 0rem;
  display: flex;
  /* margin-left: 17rem; */
  margin-left: -11rem;
}

.new-hero-search-input {
  font-size: 16px;
  padding: 10px;
  width: 300px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease; /* Smooth transition */
}

.new-hero-search-input.blue-border {
  border-color: blue; /* Border color after click */
}

.new-hero-search-button {
  font-size: 16px;
  padding: 14px 20px;
  border-radius: 0 5px 5px 0;
  border: none;
  background: #4a90e2;
  color: #fff;
}
@media (max-width: 768px) {
 
  .new-hero-title {
    font-size: 30px !important;
    margin-top: 0rem;
    /* margin-top: -11rem; */
    letter-spacing: 1.5px;
    /* margin-left: -10rem;
    margin-right: 11rem; */
    /* margin-left: -8rem; */
    /* margin-right: 12rem; */
    align-items: center;
  }

  .new-hero-description{
    font-size: 12px !important;
    line-height: 1;
    margin-bottom: 15rem;
    margin-top: 20rem;
    line-height: 1.2;
    margin-left: 3rem;
    visibility: hidden;
  }
  .new-hero-button {
    font-size: 12px;
    padding: 2px ;
    margin-left: -22rem;
     margin-top: -4rem;
    /* margin-bottom: 5rem; */
  }
  .new-hero-search-bar {
    /* margin-left: -11rem; */
    margin-left: 1rem;
    margin-top: -20rem;
  }
  .carousel-item .btn-get-started {
    font-size: 16px ;
    padding: 8px  ;
    width: 30% !important;
  }
}
@media (min-width: 1500px) and (max-width: 1790px) {
  .new-hero-search-bar{
  
    margin-left: 16rem;
  }
}
@media (max-width: 576px) {
  

  .new-hero-quote {
    font-size: 10px;
    top: 5%;
    left: 0%;
    z-index: 2;
    padding: 5px 15px;
    letter-spacing: 2px;
  }
  .new-hero-quote2 {
    font-size: 10px;
    top: 10%;
    left: 14%;
    z-index: 2;
    padding: 0px 15px;
    letter-spacing: 2px;
  }
 
  .new-hero-title {
    font-size: 20px !important;
    margin-top: 3rem;
    letter-spacing: 1.5px;
    margin-left: 0rem;
    margin-right: 11rem;
  }

  .new-hero-description{
    font-size: 12px !important;
    line-height: 1;
    margin-bottom: 15rem;
    /* margin-top: -4rem; */
    margin-top: 4rem;
    line-height: 1.2;
    margin-left: 3rem;
    visibility: hidden;
  }
  .new-hero-button {
    font-size: 12px;
    padding: 2px ;
    margin-left: 1rem;
     /* margin-top: 16rem; */
    /* margin-bottom: -15rem; */
  }
  .new-hero-search-bar {
    /* margin-top: 20rem; */
    margin-top: -8rem;
    margin-left: 0rem;
  }
  .carousel-item .btn-get-started {
    font-size: 14px !important;
    width: 50% !important;
    padding: 5px !important;
    left: 5px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .new-hero-search-bar {
    /* margin-left: 17rem; */
    margin-left: 12rem;

  }
}
@media (min-width: 919px) and (max-width: 1024px) {
  .new-hero-search-bar {
    margin-left: 8rem;
  }
}

/*  Pics Component */

.pics-container {
  background-image: url('../ImagesPhotography/bgsliderimg1.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  position: relative;
}

.pics-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
}

.pics-content h1 {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 4vw;
  white-space: normal;
  word-wrap: break-word;
}
/* top photographers */
.top-photographers-container {
  background-color: #f8f9fa;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
}

.top-photographers-banner {
  background-image: url('../ImagesPhotography/photostandmanimage.jpeg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
  position: relative;
}

.top-photographers-banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
}

.top-photographers-banner-content h1 {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.3rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 3vw;
  white-space: 'normal';
}

.top-photographers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

/* PhotographyAbout */

.photography-about-container {
  height: 650px;
  padding: 0;
  margin: 0;
}

.photography-about-banner {
  background-size: cover;
  background-position: center;
  height: 450px;
  position: relative;
  object-fit: cover;
}

.photography-about-content {
  position: absolute;
  bottom: -160px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.photography-about-description h3 {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: calc(1.5rem + 1vw);
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
 .btn-book-photographer {
  background-color: #000080;
  border: none;
  padding: 20px 20px;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 20px;
}
/* photo services */
.photo-service {
  padding: 0 60px;
}

.gallery-wrapper {
  /* max-height: 500px; */
  /* overflow-y: scroll; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  display: "flex";
  flex-direction: "column";
}



.gallery-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery-card-image {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.gallery-card-content {
  width: 100%;
  max-width: 250px;
  padding: 10px;
  background: linear-gradient(23deg, #d4d4d4 0%, #d5e6f0 35%, #eaf6fc 100%);
  margin-top: -4rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: height 0.3s ease-in-out;
  position: relative;
}

.gallery-card-content.hovered {
  height: 220px;
}

.gallery-title {
  margin: "10px 10px 0 10px";
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 25px;
}

.gallery-description {
  padding: "10px 20px 0px 20px";
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.more-info-btn {
  margin-bottom: 10px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
}

.gallery-card-content.hovered .more-info-btn {
  display: block;
}

/* studio */
.studio-photo-container {
  padding: 30px 0;
  text-align: center;
}

.text-muted {
  font-size: 18px;
}

.custom-btn {
  font-size: 16px;
  padding: 12px 30px;
}

.steps-section {
  padding-top: 40px;
}

.step-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-img {
  object-fit: contain;
  margin-bottom: 15px;
}

.step-title {
  font-weight: bold;
  color: #002366;
}

.step-text {
  font-size: 16px;
}

.step-button {
  font-size: 14px;
  padding: 10px 20px;
  margin-top: 10px;
}
.why-studio-container {
  background: linear-gradient(23deg, #d4d4d4 0%, #d5e6f0 35%, #eaf6fc 100%);
  padding: 0 60px 0 36px;
  text-align: center;
}

.why-studio-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  font-weight: 700;
}

.feature-col {
  margin-bottom: 1.5rem;
}

.feature-icon-col {
  padding-right: 0; 
  padding-left: 0;
  text-align: center; 
}

.feature-icon {
  font-size: 30px;
  margin-top: -3rem;
  text-align: center;
}

.feature-text-col {
  padding-left: 0;
  text-align: left;
  margin-bottom: 5px;
}
.feature-title {
  margin: 0 0 0.5rem;
}

.feature-description {
  font-size: 16px;
  margin: 0;
}
/* blog */
.header-container {
  position: relative;
}

.title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lora', serif;
  font-style: italic;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.title-text {
  /* font-size: 30rem; */
  position: absolute;
  margin: 0;
  padding: 30px 20px;
}

.blogheader-image {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

@media (min-width: 768px) {
  .title-text {
    font-size: 60px;
  }

  .blogheader-image {
    height: 90vh;
  }
}

@media (max-width: 767px) {
  .title-text {
    font-size: 5vw;
    line-height: 1.2;
  }

  .blogheader-image {
    height: 60vh;
  }
}
.blog-card-meta {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 15px;
}
.blog-desc {
  font-size: 18px !important;
  margin-bottom: 15px !important;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  height: 5.3rem !important;
}
.sidebar-section {
  background: #fff;
  padding: 20px 30px 30px;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.sidebar-title {
  color: #526484;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 0.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

.recent-posts-list {
  border: 1px solid #e9e9e9;
}

.recent-post-item {
  padding: 0.75rem 1rem;
  background: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 550;
}
.categories{
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom:20px;
}
.categorytitle{
  background-color: "#526484";
  -webkit-background-clip: "text";
 -webkit-text-fill-color: "transparent";
  font-size: "1.25rem";
  font-weight: "bold";
  text-align: "center";
  margin-bottom: "25px";
  margin-top:'20px !important'
}
.category-ul{
  list-style: none;
  padding-left: "0";
  margin: "0";
  display: "flex"; 
  flex-direction: "column"; 
  align-items: "center", 
}
.category-item{
  position: "relative";
  margin-bottom: "10px";
  border-radius: "10px";
  width: "90%";
  display: "flex";
  justify-content: "center";
}
.blue-bg{
  position: "absolute";
  padding: "10px";
  bottom: "0";
  top: "6px";
  right:"17px";
  width: "92%";
  height: "52px";
  background-color: "#526484";
  border-bottom-left-radius: "15px";
  border-bottom-right-radius: "15px";
  border-top-left-radius: "15px";
  border-top-right-radius: "15px";
  z-index: 0;
  transition: "top 0.3s ease";
}
.category-li {
  position: relative;
  width: 90%;
  padding: 0.75rem 1rem;
  background: #fff;
  font-size: 1rem;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid #143182;
  margin-bottom: 20px;
  text-align: center;
  z-index: 1;
}

/* contact */

/* Main Section Container */
.contact-header {
  position: relative;
  /* background-image: url("../ImagesPhotography/contact\ us\ \(1\).jpg"); */
  /* background-image: url("https://media.istockphoto.com/id/2167141366/photo/contact-us-or-customer-support-hotline-people-connect-customer-service-contact-customer-phone.jpg?s=612x612&w=0&k=20&c=GKYwLAqkSyoH1BXBE4UGpksjlst4pDbMPRrLYuvjYX8="); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 85vh; /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Title Section */

.block-container {
  padding: 30px 30px 0px 30px;
}

.custom-container {
  padding: 0px 30px;
}

.description-text {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
}
.image-class {
  width: 99%;
  height: 95%;
  object-fit: cover;
  border-radius: 15px;
  margin-top: 24px;
  margin-left: 4px;
}


/* portal */
.custom-carousel-control-prev,
.custom-carousel-control-next {
  position: absolute;
  top: 30% !important; 
  transform: translateY(-50%);
  z-index: 2; 
  width: 50px; 
  height: 50px; 
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-carousel-control-prev {
  left: -6rem !important; 
}

.custom-carousel-control-next {
  right: -6rem !important; 
}

@media (max-width: 768px) {
  .custom-carousel-control-prev,
  .custom-carousel-control-next {
    width: 35px !important; 
    height: 35px !important;
    top: 20% !important; 
    padding: 5px !important;
  }

  .custom-carousel-control-prev {
    left: -2rem; 
  }

  .custom-carousel-control-next {
    right: -2rem;
  }
  .carousel-item .portal-title{
    font-size: 30px !important;
  }

  .carousel-item .portal-desc {
    font-size: 16px !important;
    /* margin: 30px 0; */
  }

  .carousel-item .btn-get-started {
    font-size: 16px ;
    padding: 8px  ;
    width: 30% !important;
  }
}

@media (max-width: 576px) {
  .custom-carousel-control-prev,
  .custom-carousel-control-next {
    width: 25px !important; 
    height: 25px !important;
    top: 20% !important; 
    padding: 5px !important;
  }

  .custom-carousel-control-prev {
    left: -7rem !important; 
  }

  .custom-carousel-control-next {
    right: -7rem !important;
  }
  .carousel-item .portal-title {
    font-size: 18px !important;
  }

  .carousel-item .portal-desc {
    font-size: 14px !important;

  }

  .carousel-item .btn-get-started {
    font-size: 14px !important;
    width: 50% !important;
    padding: 5px !important;
    left: 5px !important;
  }
}
  
  .carousel-slide {
    position: relative;
    height: 85vh;
    background-size: cover;
    background-position: center;
  }
  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .carousel-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    width: 80%;
    z-index: 2;
  }
  .carousel-content .portal-title {
    color: white;
    font-size: 40px !important;
  }
  
  .carousel-content .portal-desc {
    font-size: 16px !important;
    color: white !important;
    /* margin: 40px 0; */
  }
  .btn-get-started {
    font-size: 18px;
    width: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: '40%';
  }
 
  .nav-item .nav-link:hover {
    color:#1c6098 !important; 
   
  }

  /* .icon-hover-effect1 {
    transition: all 0.3s ease-in-out;
  }
  
  .icon-hover-effect1:hover {
    color: rgb(12, 12, 12);
    background-color: black;
    border-color: black;
  } */
  
  .circle-hover:hover {
    background-color: rgb(105, 83, 83); 
    border-color: black;
    /* transform: scale(1.1);  */
    /* transition: all 0.3s ease; */
  }
  