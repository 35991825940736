  .construction-footer {
  background-color: #131944;
  color: #fff;
}

.construction-footer h4 {
  font-weight: bold;
  margin-bottom: 40px !important;
  text-align: center;
  color: #fff;
}

.construction-footer h5 {
  color: #fff;
  margin-bottom: 40px;
}

.construction-copyrighttext {
  justify-content: center;
  align-items: center;
}

.construction-footer p {
  line-height: 1.6;
  max-width: 500px;
  margin: 0 auto;
  color: #fff;
}

.construction-footer hr {
  border-color: #fff;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
  border-width: 1px;
}

.construction-footer ul {
  padding: 0;
  list-style: none;
}

.construction-footer ul li {
  margin-bottom: 10px;
}

.construction-footer ul li a {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
  cursor: pointer;
}

.construction-footer ul li a:hover {
  color: #FE5D14 !important;
}

.construction-footer .col-md-2 ul li a {  
  cursor: pointer;
}


.construction-footer .construction-social-icons ul {
  list-style: none;
  margin-top: 15px; 
  display: flex;
  justify-content: center; 
  padding: 0; 
}

.construction-footer .construction-social-icons ul li {
  margin: 0 10px; 
}

.construction-footer .construction-social-icons ul li a {
  font-size: 18px;
  color: #fff;
  transition: color 0.3s;
}

.construction-footer .construction-social-icons ul li a:hover {
  color: #FE5D14;
}


@media (max-width: 768px) {
  .construction-footer .col-md-5,
  .construction-footer .col-md-3,
  .construction-footer .col-md-2 {
    text-align: left; 
    margin-bottom: 10px;
  }

  .construction-footer h5 {
    font-size: 1.1rem;
  }

  .construction-footer .construction-social-icons ul {
    justify-content: center !important; 
    padding-top: 10px;
  }

  .construction-footer p {
    font-size: 0.9rem;
    max-width: 100%;
  }

  .construction-footer hr {
    width: 70%; 
  }

  .construction-footer .construction-copyrighttext {
    text-align: left; 
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .construction-footer .construction-social-icons {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .construction-footer h4 {
    font-size: 1.5rem;
    text-align: left; 
  }

  .construction-footer .container {
    padding: 20px;
  }

  .construction-footer .construction-social-icons ul {
    justify-content: center; 
    padding-top: 10px;
  }

  .construction-footer .construction-social-icons ul li {
    margin-right: 15px;
  }

  .construction-footer .col-md-5 img {
    width: 200px;
    height: 120px;
  }

  .construction-footer hr {
    width: 90%; 
  }

  .construction-footer .construction-copyrighttext {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .construction-footer .construction-social-icons {
    text-align: center; 
    padding-top: 10px;
    margin-left: 0; 
  }
}  
