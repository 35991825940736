.homeImage {
  background-image: linear-gradient(180deg, rgba(6, 12, 34, 0.7) 0%, rgba(6, 12, 34, 0.8) 100%),
    url("../../assets//images//homeBg.jpg");
  /* ../assets/images/homeBg */
}

.cardBg {
  background-color: #060c22c2;
}

.headerBg {
  background-color: #060c22fa;
}

.custom-select {
  width: 100%; /* Adjust width as needed */
  padding: 0.375rem 1.75rem 0.375rem 0.75rem; /* Adjust padding for styling */
  font-size: 1rem; /* Adjust font size */
  line-height: 1.5; /* Adjust line height */
  color: #495057; /* Adjust text color */
  background-color: #fff; /* Adjust background color */
  /* background-clip: padding-box; */
  border: 1px solid #ced4da; /* Adjust border color */
  border-radius: 0.25rem; /* Adjust border radius */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
  border-color: #80bdff; /* Adjust border color on focus */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Adjust box shadow on focus */
}

.hero-image-container {
  position: relative;
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 15% 100%, 0% 50%);
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.hero-image:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* .cursor-pointer {
  cursor: pointer;
} */
